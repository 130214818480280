//Bootstrap styles 
@import "node_modules/bootstrap/scss/bootstrap";

//Custom mixins
@import "mixins/mixins";

//GIA custom settings --
@import "settings";

//Component specific styles 
@import "components/callout";

//Form specific styles
@import "forms/buttons";

//animate.sass
@import "node_modules/animate-sass/animate";

//burgee menu
@import "vendors/burger";

@font-face {
	font-family: 'Rock Salt';
	font-style: normal;
	font-weight: normal;
	src:
		local('Rock Salt Regular'),
		local('RockSalt-Regular'),
		/* from https://fonts.gstatic.com/s/rocksalt/v8/MwQ0bhv11fWD6QsAVOZrt0M-.eot */
		url('../fonts/Rock_Salt.eot?#iefix') format('embedded-opentype'),
		/* from https://fonts.gstatic.com/s/rocksalt/v8/MwQ0bhv11fWD6QsAVOZrt0M8.woff */
		url('../fonts/Rock_Salt.woff') format('woff'),
		/* from https://fonts.gstatic.com/s/rocksalt/v8/MwQ0bhv11fWD6QsAVOZrt0M6.woff2 */
		url('../fonts/Rock_Salt.woff2') format('woff2'),
		/* from https://fonts.gstatic.com/l/font?kit=MwQ0bhv11fWD6QsAVOZrt0M9&skey=53155c62c0f0411&v=v8#RockSalt */
		url('../fonts/Rock_Salt.svg#RockSalt') format('svg'),
		/* from https://fonts.gstatic.com/s/rocksalt/v8/MwQ0bhv11fWD6QsAVOZrt0M_.ttf */
		url('../fonts/Rock_Salt.ttf') format('truetype');
}

b-link, .navbar, h1, h2, h3, h4, h5, h6  { 
	text-decoration: none !important;
}

.show-for-small-only, 
.show-on-small { display: none; }


@media screen and (max-width: 650px) {
  .show-for-small-only,
  .show-on-small {
    display: block !important; }
  .hide-on-small { display: none !important; } 
}

    
div.lang {
	
	position: absolute;
	top: 10px;
	right: 120px;
}

.rocksalt  { 
	font-family: 'Rock Salt', sans-serif; 
}

.sans { font-family: sans-serif; }


//body { @include background("../img/33_hell-klein.jpg"); }

body { overflow-x: hidden; }

.container { 
	padding-top: 350px; 
	@media (max-width: 450px) { padding-top: 75px;  }
	@media (max-width: 576px) { padding-top: 250px;  }
	@media (max-width: 768px) { padding-top: 300px; }
	padding-bottom: 100px;
}

.b-container { width: 100%; }

.green { background: color(primary); } 
.btn {
	background: color(primary);
	color: white;
	font-family: sans-serif; 
}

.speech-bubble-r,  .speech-bubble-l,  .speech-bubble-b {
	position: relative; 
	background: #fff; 
	border-radius: .4em;
	border: 3px solid black;
	padding: 30px;
}

.speech-bubble-l:after { 
	content: ''; position: 
	absolute; 
	left: 0; 
	top: 50%; 
	width: 0; 
	height: 0; 
	border: 20px solid transparent; 
	border-right-color: black;
	border-left: 0;
	margin-top: -20px;
	margin-left: -20px; 
}

.speech-bubble-r:after { 
	content: ''; 
	position: absolute; 
	right: 0; 
	top: 50%; 
	width: 0; 
	height: 0; 
	border: 20px solid transparent; 
	border-left-color: black; 
	border-right: 0; 
	margin-top: -20px; 
	margin-right: -20px; 
}

.speech-bubble-b:after { 
	content: ''; 
	position: absolute; 
	bottom: 0; 
	left: 50%; 
	width: 0; 
	height: 0; 
	border: 20px solid transparent; 
	border-top-color: black; 
	border-bottom: 0; 
	margin-left: -20px; 
	margin-bottom: -20px; 
}
